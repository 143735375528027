import React, { useEffect, useMemo, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import highcharts-more module
import HighchartsXrange from 'highcharts/modules/xrange';
import moment from 'moment-timezone';

// HighchartsMore(Highcharts); 
// Initialize the highcharts-more module
// HighchartsXrange(Highcharts);

// global constants
const timeAdjustmentForIST = 19800; // 5.5 hours
const secondsInaDay = (3600 * 24);
const localTimeZone = 'Asia/Kolkata';
const onColor = '#5AD1AA';
const offColor = '#43EBFF';

function findMaxCount(filteredData) {
  // do an initial count of the day with max events
  const countOccurrences = filteredData.reduce((acc, curr) => {
    acc[curr.date] = (acc[curr.date] || 0) + 1;
    return acc;
  }, {});

  let maxDateLabel = null;
  let maxCount = 0;
  for (const [name, count] of Object.entries(countOccurrences)) {
    if (count > maxCount) {
      maxDateLabel = name;
      maxCount = count;
    }
  }

  return { maxDateLabel, maxCount };
}

function findMaxCountPostProcessing(series) {
  let newMaxDateLabel = null;
  let newMaxCount = 0;
  for (const [name, data] of Object.entries(series)) {
    let count = data.length;
    if (count > newMaxCount) {
      newMaxDateLabel = name;
      newMaxCount = count;
    }
  }
  return { newMaxDateLabel, newMaxCount };
}

function processSingleRecord(item, series, dateLabel, runningCounter,
  lastETime, maxCount, categories, aggOptSummary, onWindows) {
  
  let lastETimeVal = lastETime;

  let adjustedStartTime = item.starttime + timeAdjustmentForIST;
  let adjustedEndTime = item.endtime + timeAdjustmentForIST;
  let startTimeSeconds = adjustedStartTime % secondsInaDay;
  
  if (dateLabel == "") {
    dateLabel = item.date;
    categories.push(dateLabel);
    runningCounter = 0;

    lastETimeVal = 0;
    // check if the time needs to be filled 
    if (startTimeSeconds >= 0) {
      lastETimeVal = adjustedStartTime - (startTimeSeconds);
    }
    aggOptSummary[dateLabel] = 0;
  }
  else if (dateLabel != item.date) {
    // close out the last date data
    // fill the rest of the counters with empty
    for (++runningCounter; runningCounter < maxCount; runningCounter++) {
      if (series[runningCounter] == null) {
        // insert a new series
        series.push({
          name: dateLabel + "-" + runningCounter,
          data: []
        })
      }
      
      series[runningCounter].data.push({ y: 0, color: (item.acstatus === "ON" ? onColor : offColor), starttime: item.starttime, endtime: item.endtime });
    }

    // reset the variables for next date processing
    dateLabel = item.date;
    categories.push(dateLabel);
    aggOptSummary[dateLabel] = 0;
    runningCounter = 0;
    lastETime = 0;
    // TODO for completeness we should even handle the last data block that is not having endtime equal to end of day time        
    // check if the time needs to be filled 
    if (startTimeSeconds > 0) {
      lastETime = adjustedStartTime - (startTimeSeconds);
    } else {
      lastETime = adjustedStartTime;
    }

  } else {
    runningCounter++;
  }
  if (series[runningCounter] == null) {
    // insert a new series
    series.push({
      name: dateLabel + "-" + runningCounter,
      data: []
    })
  }

  if (lastETimeVal != 0 && (adjustedStartTime - lastETimeVal > 1)) {
    // TODO handle the scenario where the maxDateLabel which creates the largest number of time block is missing some data.
    // then it needs to be added across all the other ones.
    // may be it is a good idea to process it earlier and get count or may be fix the count logic
    // we do not what this is        
    series[runningCounter].data.push({ y: (adjustedStartTime - lastETimeVal) * 1000, color: "gray", starttime: item.starttime, endtime: item.endtime });
    runningCounter++;
    // adding the additional counter for the regular block
    series.push({
      name: dateLabel + "-" + runningCounter,
      data: []
    })
    lastETimeVal = adjustedStartTime;
  }
  //#3EB488, #0094FB other colors
  // sum up all the ac on times for a date
  aggOptSummary[dateLabel] += (item.acstatus === "ON"? (adjustedEndTime - adjustedStartTime):0);
  if(item.acstatus === "ON"){
    onWindows.push({start: item.starttime, end: item.endtime});
  }
  series[runningCounter].data.push({ y: (adjustedEndTime - adjustedStartTime) * 1000, color: (item.acstatus === "ON" ? onColor : offColor), starttime: item.starttime, endtime: item.endtime });
  lastETimeVal = adjustedEndTime;
  return {runningCounter: runningCounter, lastETime: lastETimeVal, dateLabel:dateLabel};
}

function isProcessInTimeWindow(timeWindows, processStartTime, processEndTime) {
  
  return timeWindows.some(window => {
    // Check if processStartTime or processEndTime falls within this time window
    const status = (
      (processStartTime >= window.start && processStartTime <= window.end) &&
      (processEndTime >= window.start && processEndTime <= window.end)
    );
    return status
  });
}

function changeCompStatus(compstatus, isAnOnRecord) {   
  if(compstatus === "COMPOFF+OPT") 
    return  "Device Cut off" 
  else if (compstatus === "--")
    return "No Data"
  else if (compstatus === "COMPON")
    return "Compressor On"
  else if (compstatus === "COMPOFF+THRMO")
    return "Thermostat Cut off"
  else if(compstatus === "COMPOFF" && isAnOnRecord)
    return "Thermostat Cut off"
  else  // COMPOFF && !isAnOnRecord
    return "Device off"
}

function processFilteredAggregatedData(filteredAggregatedData, timeWindows) {
  let newTableData = [];

  // Process the initial data
  filteredAggregatedData[0].data.forEach((entry, index) => {
      // Convert from and to timestamps to date strings in localTimeZone timezone
      const fromDateStr = moment.unix(entry.from).tz(localTimeZone).format('YYYY-MM-DD');
      const toDateStr = moment.unix(entry.to).tz(localTimeZone).format('YYYY-MM-DD');
      const startOfTimestamp = moment(fromDateStr).tz(localTimeZone).startOf('day').unix();

      // Check if 'from' and 'to' are on the same day
      if (fromDateStr === toDateStr) {
          if (index === 0 && entry.from > startOfTimestamp) {
              const isAnOnRecord = isProcessInTimeWindow(timeWindows, startOfTimestamp, entry.from);
              newTableData.push({
                  ...entry,
                  from: startOfTimestamp,
                  to: entry.from,
                  fromDate: fromDateStr,
                  toDate: toDateStr,
                  compstatus: "--",
                  OptimizerMode: "N/A",
                  isAnOnRecord: isAnOnRecord,
                  ncompstatus: "No Data"
              });
          }
          const isAnOnRecord = isProcessInTimeWindow(timeWindows, entry.from, entry.to);
          newTableData.push({
              ...entry,
              fromDate: fromDateStr,
              toDate: toDateStr,
              isAnOnRecord: isAnOnRecord,
              ncompstatus: changeCompStatus(entry.compstatus, isAnOnRecord)
          });
      } else {
          // Create first entry from 'from' to midnight of the next day
          const midnightOfNextDay = moment(fromDateStr).tz(localTimeZone).add(1, 'day').startOf('day').unix();
          const preDayBreakRecord = isProcessInTimeWindow(timeWindows, entry.from, midnightOfNextDay);
          newTableData.push({
              ...entry,
              to: midnightOfNextDay,
              fromDate: fromDateStr,
              toDate: fromDateStr,
              isAnOnRecord: preDayBreakRecord,
              ncompstatus: changeCompStatus(entry.compstatus, preDayBreakRecord)
          });

          // Insert "No Data" entries for any full-day gaps between 'to' and 'from' days
          const dayBreakTime = moment(toDateStr).tz(localTimeZone).startOf('day').unix();
          let nextDay = midnightOfNextDay;

          while (nextDay < dayBreakTime) {
              newTableData.push({
                  from: nextDay,
                  to: moment.unix(nextDay).add(1, 'day').startOf('day').unix(),
                  fromDate: moment.unix(nextDay).tz(localTimeZone).format('YYYY-MM-DD'),
                  toDate: moment.unix(nextDay).tz(localTimeZone).format('YYYY-MM-DD'),
                  compstatus: "--",
                  OptimizerMode: "N/A",
                  isAnOnRecord: false,
                  ncompstatus: "No Data"
              });
              nextDay = moment.unix(nextDay).add(1, 'day').startOf('day').unix();
          }

          // Create second entry from the start of 'to' day to the actual 'to' time
          const postDayBreakRecord = isProcessInTimeWindow(timeWindows, dayBreakTime, entry.to);
          newTableData.push({
              ...entry,
              from: dayBreakTime,
              fromDate: toDateStr,
              toDate: toDateStr,
              compstatus: "--",
              OptimizerMode: "N/A",
              isAnOnRecord: postDayBreakRecord,
              ncompstatus: "No Data"
          });
      }
  });

  return newTableData;
}

const AcStatus = ({ data, aggData, closeModal, id }) => {
  const [tableData, setTableData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [FilteredData, setFilteredData] = useState([]);
  const [filteredAggData, setFilteredAggData] = useState([]);
  const [aggOptSummary, setAggOptSummary] = useState({});
  const [showingAllData, setShowingAllData] = useState(true);
  const [partialOnValue, setPartialOnValue] = useState(0);
  const [onWindows, setOnWindows] = useState([]);
  const [clickData, setClickData] = useState({}); 
  /*
    code logic
    filter the data to get a single optimizer id
    the problem statement is that the we want to create the chart in a stack structure
    the way the stack works is each category becomes a column
    the expectation of each data element in the series of data is passed is that 
    each element, should have the same count of subelements in it
    example: if i want to create a graph of two days of on off
    then the data could be
    day1: 3-6 (off), 6-9 (on), 9-15 (off), 15-24(on)  -- 0-3 is missing, 4 events
    day2: 0-15 (off), 15-24 (on)
    day3: 0-3 (off), 6-15 (on), 15-24(on)  -- 3-6 is missing, 3 events
    
    but this data in stack will not create the right structure as
    day1: is missing 0-3 ()
    day1 and day2 have different number of events.
    day1 has 4 events on on and off
    day2 has 2 events
    day3 gas 3 events

    the entire code from "stack data logic - start" to "end" is to generate data like this
    each block of events computes the time for that event
    day1: 3(nostatus), 3 (off), 3 (on), 6 (off), 9(on)  -- 5 events
    day2: 15 (off), 9 (on), 0(nostatus), 0(nostatus), 0(nostatus) -- 5 events
    day3: 3 (off), 3(nostatus), 9(on), 9(off), 0(nostatus) -- 5 events
    ---Note-- 
    day1: added additional 3(nostatus) to fill the initial gap
    day2: populated additional 0 events to match the count count of events in day1
    day3: added additional 3(nostatus) to fill the gap in the middle, and 0 event to match counts
  */
  useEffect(() => {
    let filteredData = id ? data.filter(element => element.optimizerId === id) : [];
    setFilteredData(filteredData);    
    // find the dateWith the maxCounter
    let { maxDateLabel, maxCount } = findMaxCount(filteredData);
    // process each record and populate the categories and series
    let categories = [];
    let dateLabel = "";
    let runningCounter = 1;
    let series = [];
    let lastETime = 0;
    let aggOptSummary = {};
    let onWindowslist = [];
    filteredData.forEach(item => {
      let returnedValues = processSingleRecord(item, series, dateLabel, runningCounter,
        lastETime, maxCount, categories, aggOptSummary, onWindowslist);      
      dateLabel = returnedValues.dateLabel;
      runningCounter = returnedValues.runningCounter;
      lastETime = returnedValues.lastETime;      
    });
    // set the on windows
    setOnWindows(onWindowslist);
    // create chart level aggregation to capture acOnTime
    setAggOptSummary(aggOptSummary);

    let filteredAggregatedData = id ? aggData.filter(element => element.oid === id) : [];
    if(filteredAggregatedData.length > 0){
      // sanitize the data before use
      const newTableData = processFilteredAggregatedData(filteredAggregatedData, onWindowslist);
      setFilteredAggData(newTableData);
      setTableData(newTableData);
    }else{
      // do nothing
    }
    
    // check and fix the counters again
    let { newMaxDateLabel, newMaxCount } = findMaxCountPostProcessing(series);  

    setChartOptions({
      chart: {
        type: 'column'
      },
      title: {
        text: 'AC On Off Times',
        align: 'left'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {

        type: 'datetime',
        title: {
          text: 'Time'
        },
        dateTimeLabelFormats: {
          hour: '%H:%M:%S',
          minute: '%H:%M:%S',
          second: '%H:%M:%S'
        },
        tickInterval: 3 * 3600 * 1000, // 1 hour
        labels: {
          format: '{value:%H:%M:%S}'
        },
        min: 0,
        max: 24 * 3600 * 1000,
        stackLabels: {
          enabled: false
        },
        reversedStacks: false
      },

      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function () {  
          const status = this.point.color === onColor ? "ON" : "OFF";   
          const startTime = moment.unix(this.point.starttime).tz(localTimeZone).format('HH:mm');
          const endTime = moment.unix(this.point.endtime).tz(localTimeZone).format('HH:mm');      
          const timeInHoursMinSec = convertToHourMins(this.y/1000); 
          return `<b>${this.x}</b><br/> StartTime: ${startTime}<br/>EndTime: ${endTime}<br/>Status: ${status} <br/>`;
          // return `<b>${this.x}</b><br/> Time: ${timeInHoursMinSec}s<br/>Status: ${status} <br/>`;
        }       
      },
      credits:{
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          },
          pointPadding: 0,
          point: {
            events: {
              click: function () {
                // Update selected time based on clicked point
                
                const { y, color, starttime, endtime } = this;
                setClickData({y:y, color:color, starttime:starttime, endtime:endtime});
                
              }
            }
          }
        }
      },
      series: series
    })
  }, [data, aggData, id]);

  useEffect(() => {
      const filtered = filteredAggData.filter(item =>
        ((item.from >= clickData.starttime && item.to <= clickData.endtime) || 
          (item.from >= clickData.starttime && item.from < clickData.endtime && item.to >= clickData.endtime))
      );
      setTableData(filtered);
      // to reset the page back to 1
      setCurrentPage(1);
      // signal that we are showing partial data
      setShowingAllData(false);
      
      if(clickData.color === onColor){
        setPartialOnValue(clickData.y/1000);
      } else{
        setPartialOnValue(0);
      }
  }, [clickData])

  const handleReset = () => {
    setShowingAllData(true);
    setTableData(filteredAggData);
    setPartialOnValue(0);
  }  

  const [aggSummary, setAggSummary] = useState({data:[]});
  useEffect(() => {
    const summary = tableData.reduce(
      (totals, item) => {
        // calculate date
        const timewindows = [];

        if(item.fromDate === item.toDate){
          timewindows.push({dateStr: item.fromDate, timeDiff:(item.to - item.from)});
        }else{
          // get the item.toDate unix time in IST, let's say it unixToTime
          const unixToTime = moment.tz(item.toDate, localTimeZone).valueOf()/1000;
          // diff that time with the "from" time to get diff 1
          timewindows.push({dateStr: item.fromDate, timeDiff:(unixToTime - item.from)});
          // diff "to" time with the unixToTime to get diff 2
          timewindows.push({dateStr: item.toDate, timeDiff:(item.to - unixToTime)});
          // add the diffs
        }
        
        timewindows.map(timewindow => {          
          const timeDiff = timewindow.timeDiff;
          const dateStr = timewindow.dateStr;
          if((totals.length === 0) || ( dateStr !== totals.date)){
            totals.date = dateStr;
            totals.index++;
            totals.data.push({ date: dateStr, time: 0, compon: 0, devicecutoff:0, thermostatcutoff:0, acOnNoData:0, nodata:0, optimizationMode:0, nonOptimizationMode:0 })
          }
          const currentObj = totals.data[totals.index];

          currentObj.time += timeDiff;
          // currentObj.compon += item.compstatus === "COMPON" ? timeDiff + 180 : 0;
          currentObj.compon += item.compstatus === "COMPON"? timeDiff: 0;
          currentObj.devicecutoff += item.compstatus === "COMPOFF+OPT"? timeDiff : 0;
          currentObj.thermostatcutoff += ((item.compstatus === "COMPOFF" && item.isAnOnRecord) || item.compstatus === "COMPOFF+THRMO") ? timeDiff: 0;
          currentObj.acOnNoData += (item.isAnOnRecord && item.compstatus === "--"? timeDiff:0);
          currentObj.nodata += item.OptimizerMode === "N/A" ? timeDiff : 0;          
          currentObj.optimizationMode += item.OptimizerMode === "OPTIMIZATION" ? timeDiff:0;
          currentObj.nonOptimizationMode += item.OptimizerMode === "NON-OPTIMIZATION" ? timeDiff:0;
        })  
        return totals;
      },
      {index:-1, date:"", data:[]} 
    );// Initial values for totals
    
    setShowingAllData(true);      
    console.log("summary", summary);
    setAggSummary(summary);
  }, [tableData]);

  // const convertToHourMins = (timeInSeconds) => {
  //   return (new Date(timeInSeconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
  // };

  // Function to format time into hrs:min:sec format
  const convertToHourMins = (totalSeconds) => {
    if(!totalSeconds){
      return "00:00:00";
    }
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours<10? "0"+hours: hours}:${minutes<10? "0"+minutes: minutes}:${seconds<10? "0"+seconds: seconds}`;
  };
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const paginationRange = 1;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);  
  const [displayedData, setDisplayedData] = useState([]);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(tableData.length / itemsPerPage);

    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(tableData.length / itemsPerPage);

    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, i) => i + 1).map((i) => (
        <li key={i}>
          <button
            className={`px-3 py-1 rounded-md ${currentPage === i
              ? "text-white bg-purple-600 border border-r-0 border-purple-600"
              : "focus:outline-none focus:shadow-outline-purple"
              }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      ));
    }

    const pages = [];
    const startPage = Math.max(1, currentPage - paginationRange);
    const endPage = Math.min(totalPages, startPage + 2 * paginationRange);
    if (startPage > 1) {
      pages.push(
        <li key={1}>
          <button
            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        </li>
      );

      if (startPage > 2) {
        pages.push(<span key="startEllipsis">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i}>
          <button
            className={`px-3 py-1 rounded-md ${currentPage === i
              ? "text-white bg-purple-600 border border-r-0 border-purple-600"
              : "focus:outline-none focus:shadow-outline-purple"
              }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="endEllipsis">...</span>);
      }

      pages.push(
        <li key={totalPages}>
          <button
            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }
    return pages;
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    setStartIndex(startIndex);
    const endIndex = startIndex + itemsPerPage;
    setEndIndex(endIndex);
    const displayedData = tableData.slice(startIndex, endIndex);    
    setDisplayedData(displayedData);
  },[currentPage, tableData]);
  
  return (id === ""? "": (
    <div className="container mt-4">
      {/* <h4 className="text-center mb-3">AC ON/OFF Details</h4> */}
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
            />
          </div>
          <div className="col-md-6">          
            <table className="table mt-0 text-xs font-semibold tracking-wide text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800" >
              <thead className="table-dark uppercase">
                <tr>
                  <th>Date</th>
                  <th>Comp Status</th>
                  <th>Opt Mode</th>
                  <th scope="col">Start</th>
                  <th scope="col">Stop</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                {displayedData.map((item, index) => (
                  <tr key={index}>
                    <td>{moment.unix(item.from ? item.from : item.to).tz(localTimeZone).format('YYYY-MM-DD')}</td>
                    <td>{item.ncompstatus}</td>
                    <td>{item.OptimizerMode === "NON-OPTIMIZATION"? "Non Optimization" : (item.OptimizerMode === "OPTIMIZATION"? "Optimization": "")}</td>
                    <td>{item.from ? new Date(item.from * 1000).toLocaleTimeString() : '--'}</td>
                    <td>{item.to ? new Date(item.to * 1000).toLocaleTimeString() : '--'}</td>

                    {/* <td>{moment.unix(item.from ? item.from : item.to).tz(localTimeZone).format('YYYY-MM-DD')}</td>
                    <td>{item.compstatus} : {item.isAnOnRecord? "Y" : "N"}</td>
                    <td>{item.OptimizerMode}</td>
                    <td>{item.from ? new Date(item.from * 1000).toLocaleTimeString() : '--'}</td>
                    <td>{item.to ? new Date(item.to * 1000).toLocaleTimeString() : '--'}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="grid px-0 py-0 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
              <span className="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
                <nav aria-label="Table navigation">
                <button
                    className="px-3 py-1 rounded-md text-white bg-purple-600 border border-r-0 border-purple"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <ul className="inline-flex items-center">
                    <li>
                      <button
                        className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                        aria-label="Previous"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 fill-current"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </li>
                    {renderPaginationButtons()}
                    <li>
                      <button
                        className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                        aria-label="Next"
                        disabled={currentPage === Math.ceil(tableData.length / itemsPerPage)}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <svg
                          className="w-4 h-4 fill-current"
                          aria-hidden="true"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </span>
            </div>
          </div>
        </div>
        <div className="row"> 
          <div className="col-md-12">         
            <table className="table table-bordered mt-0 text-xs text-center font-semibold tracking-wide text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
              <thead className="table-dark">
              <tr>
                <th rowSpan={2}>Date</th>
                <th rowSpan={2}>Total Time(hrs)</th>
                <th colSpan={3}>TotalTime(hrs)</th>
                <th rowSpan={2}>AC On Time</th>                  
                <th colSpan={4}>Total On Time(hrs)</th>
              </tr>                
              <tr>
                <th>Time in Opt Mode</th>
                <th>Time in Non-Opt Mode</th>
                <th>No Data</th>
                <th>Comp On Time</th>
                <th>Device Cut Off Time</th>
                <th>Therm Cut Off Time</th>
                <th>No Data</th>                 
              </tr>                
              </thead>
              <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">                
                
                {aggSummary.data.map((item) => (
                  <tr>
                    <td>{item.date}</td>
                    <td>{convertToHourMins(item.time)} </td>
                    <td>{convertToHourMins(item.optimizationMode)}</td>
                    <td>{convertToHourMins(item.nonOptimizationMode)}</td>
                    <td>{convertToHourMins(item.nodata)}</td>
                    <td>{convertToHourMins(showingAllData? aggOptSummary[item.date] : partialOnValue)}</td>
                    <td>{convertToHourMins(item.compon)}</td>   
                    <td>{convertToHourMins(item.devicecutoff)}</td>
                    <td>{convertToHourMins(item.thermostatcutoff)}</td>
                    <td>{convertToHourMins(item.acOnNoData)}</td>                  
                  </tr>               
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ))

};

export default AcStatus;