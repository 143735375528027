import React, { useEffect, useState } from "react";
import LeftMenuList from "../../Common/LeftMenuList";
import TopNavbar from "../../Common/TopNavbar";
import "daterangepicker";
import { useSelector, useDispatch } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import { fetchEnterprises } from "../../Slices/Enterprise/NewEnterpriseDataSlice";
import { ByPassListing } from "../../Slices/Enterprise/ByPassSlice";
import ByPassList from "./ByPassList";
import ScheduleByPassModal from '../../Modals/AddModals/ScheduleByPassModal';


function ByPass() {
  const dispatch = useDispatch();
  const { enterprises, loading, error } = useSelector((state) => state.enterpriseDataSlice);
  const { clear_bypasslist_response, clear_bypasslist_error } = useSelector((state) => state.byPassSlice);

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState("");
  const [selectedCountryState, setSelectedCountryState] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedGateway, setSelectedGateway] = useState("");
  const [selectedOptimizer, setSelectedOptimizer] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [settingsComplete, setSettingsComplete] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  

  // page loading events - start

  useEffect(() => {
    dispatch(fetchEnterprises({ header })); 
  }, [dispatch]);

  useEffect(() => {
    if (trigger && selectedEnterprise) {
      dispatch(ByPassListing({ EnterpriseId: selectedEnterprise, header }))
        .then((response) => {
          const fetchedList = response.payload;
          if (fetchedList && fetchedList.data) {
            setTableData(fetchedList.data.length > 0 ? fetchedList.data : []);
          } else {
            setTableData([]);
          }
          setTrigger(false);
        });
    }
  }, [trigger, selectedEnterprise, dispatch]);

  // page loading events - end
  const handleEnterpriseChange = (event) => {
    const enterpriseId = event.target.value;
    setSelectedEnterprise(enterpriseId);
  };

  const handleApply = () => {
    if (selectedEnterprise) {
      setTrigger(true);
    } else {
      setTableData([]); // Clear data when no enterprise is selected
    }
  };

  // validation
  const lockUnlockSettings = (event) => {
    const newErrors = {
      country: selectedEnterprise === "",
      state: selectedCountryState === "",
      location: selectedLocation === "",
    };
    // meterData usageTrend deviceData
    setErrors(newErrors);
    if (newErrors.country || newErrors.state || newErrors.location) {
      newErrors.message = "Country, State, Location is required";
    }

    if (!newErrors.message) {
      if (formDisabled) {
        setFormDisabled(false);
        setSettingsComplete(false);
      } else {
        setFormDisabled(true);
        setSettingsComplete(true);
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTrigger(true); 
  };

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <LeftMenuList />
      <div className="flex flex-col flex-1 w-full">
        <TopNavbar />

        <main className="h-full overflow-y-auto">
          <div className="container px-6 mx-auto grid">
            <div className="flex justify-between">
              <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                By Pass
              </h2>
              <div className="px-6 my-6">
                <button
                  onClick={openModal}
                  className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                  Add ByPass
                  <span className="ml-2" aria-hidden="true"> +</span>
                </button>

                {isModalOpen && (
                  <ScheduleByPassModal
                  formDisabled={formDisabled}
                  // hideOptimizer={true}
                  closeModal={() => closeModal()}
                />
                )}
              </div>
            </div>

            <div>
              <div className="w-50 mb-6">
                <form action="">
                  <div className="flex space-x-3 w-full ">
                    <label className="w-full block mt-4 text-sm flex justify-between space-x-3 items-center">
                      <span className="text-gray-700 dark:text-gray-400">
                        Enterprise
                      </span>
                      <select
                        className="w-full block mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        onChange={handleEnterpriseChange}
                        value={selectedEnterprise}
                      >
                        <option value="">Select Enterprise</option>
                        {enterprises.map((enterprise) => (
                          <option
                            key={enterprise.enterpriseName}
                            value={enterprise.entepriseId}
                          >
                            {enterprise.enterpriseName}
                          </option>
                        ))}
                      </select>
                    </label>
                    <div className="flex justify-between space-x-3 items-center">
                      <button
                        type="button"
                        onClick={handleApply}
                        className="px-3 py-2 mt-3 focus:outline-none text-white rounded-lg bg-purple-600 active:bg-purple-600"
                      >
                        Apply
                      </button>
                    </div>
  
                  </div>
                </form>
              </div>
            </div>
            <hr />
            {/* Display bypass listing based on selected enterprise */}
            <ByPassList bypassList={tableData} />

            <hr />
          </div>
        </main>
      </div>
    </div>
  );
}

export default ByPass;
