import React from "react";
import SuperAdminRoutes from "./Route/SuperAdminRoutes";
import "./App.css";

function App() {
  return (
    <div>
      <SuperAdminRoutes />
    </div>
  );
}

export default App;

