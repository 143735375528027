import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import ByPassDeleteModal from "../../Modals/DeleteModals/ByPassDeleteModal";
import { TurnOnOffBypass} from "../../Slices/Enterprise/ByPassSlice";

const ByPassList = ({ bypassList }) => {
  const dispatch = useDispatch();
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [toggleStates, setToggleStates] = useState({});

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  // Function to open delete modal
  const openDeleteModal = (row) => {
    setSelectedDeleteItem(row);
    setIsDeleteModelOpen(true);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const paginationRange = 1;

  const totalPages = Math.ceil(bypassList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, bypassList.length);
  const displayedData = bypassList.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Render pagination buttons
  const renderPaginationButtons = () => {
    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, i) => i + 1).map((i) => (
        <li key={i}>
          <button
            className={`px-3 py-1 rounded-md ${
              currentPage === i
                ? "text-white bg-purple-600 border border-r-0 border-purple-600"
                : "focus:outline-none focus:shadow-outline-purple"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      ));
    }
    const pages = [];
    const startPage = Math.max(1, currentPage - paginationRange);
    const endPage = Math.min(totalPages, startPage + 2 * paginationRange);
    if (startPage > 1) {
      pages.push(
        <li key={1}>
          <button
            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        </li>
      );

      if (startPage > 2) {
        pages.push(<span key="startEllipsis">...</span>);
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i}>
          <button
            className={`px-3 py-1 rounded-md ${
              currentPage === i
                ? "text-white bg-purple-600 border border-r-0 border-purple-600"
                : "focus:outline-none focus:shadow-outline-purple"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      );
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="endEllipsis">...</span>);
      }

      pages.push(
        <li key={totalPages}>
          <button
            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pages;
  };

  const convertUnixToFormattedDate = (unixTimestamp) => {
    return moment.unix(unixTimestamp).format("DD/MM/YYYY HH:mm:ss");
  };

  const currentTime = Date.now();

  if (!Array.isArray(bypassList)) {
    console.error("bypassList is not an array:", bypassList);
    return <p>No data available.</p>;
  }

  const getStatus = (row) => {
    const currentTime = Date.now();
    const startTime = row.starttime * 1000;
    const endTime = row.endtime ? row.endtime * 1000 : null;

    if (row.bypasstype === "schedule" && startTime > currentTime && row.status === "active") {
        return "Scheduled"; 
    } else if (startTime <= currentTime && row.status === "active" && row.devicestatus === null) {
        return "InProgress - Pending Ack";
    }else if (startTime <= currentTime && row.status === "active" && row.devicestatus === "active") {
        return "InProgress";
    } else if (row.status === "inactive" && endTime <= currentTime && row.devicestatus === "active") {
        return "Completed - Pending Ack";
    }else if (row.status === "inactive" && endTime <= currentTime && row.devicestatus === null){
      return "Completed - Device Not Bypassed";
    }
    return row.status;
  };

  const handleToggleChange = (index, row) => {
    if (row.status === "active" && row.devicestatus === "active") {
      const newStatus = "off"; 
      const optimizerId = row.optimizerId || undefined;
  
      const data = {
        OptimizerId: optimizerId !== '--' ? optimizerId : undefined,
        Status: newStatus,
        GatewayID: row.gatewayId,
      };
  
      // Dispatch the TurnOnOffBypass action
      dispatch(TurnOnOffBypass({ data: data, header }))
        .then(() => {
          setToggleStates((prevStates) => ({
            ...prevStates,
            [index]: !prevStates[index],
          }));
          bypassList[index].status = newStatus; // Set the new status to "off"
        })
        .catch((error) => {
          console.error("Failed to toggle bypass:", error);
        });
    } else {
      console.log("Toggle off is not allowed because status or devicestatus is not active.");
    }
  };
  
  const handleDeleteSuccess = () => {
    setIsDeleteModelOpen(false);
  };
  

  return (
    <div data-tab-content="" className=" px-2 py-4 overflow-x-auto">
      <div className="block opacity-100" id="matadeta" role="tabpanel">
        <table className="min-w-full divide-y divide-gray-200 table-auto">
          <thead>
            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
              {/* <th className="px-4 py-3">Enterprise Name</th> */}
              <th className="px-4 py-3">State</th>
              <th className="px-4 py-3">Location</th>
              <th className="px-4 py-3">Gateway ID</th>
              <th className="px-4 py-3">Optimizer Name</th>
              <th className="px-4 py-3">ByPass Type</th>
              <th className="px-4 py-3">ByPass Status</th>
              <th className="px-4 py-3">ByPass Acknowledged</th>
              <th className="px-4 py-3">StartTime</th>
              <th className="px-4 py-3">EndTime</th>
              <th className="px-4 py-3">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {displayedData.length === 0 ? (
              <tr>
                <td colSpan="10" className="text-center py-4">
                  No data found
                </td>
              </tr>
            ) : (
              displayedData.map((row, index) => {
                // Convert startTime to milliseconds for comparison
                const startTimeMillis = row.starttime * 1000;

                return (
                  <tr key={index}>
                    {/* <td className="px-4 py-3 text-sm">
                      {bypass.enterprisename}
                    </td> */}
                    <td className="px-4 py-3 text-sm">{row.state}</td>
                    <td className="px-4 py-3 text-sm">{row.location}</td>
                    <td className="px-4 py-3 text-sm">{row.gateway}</td>
                    <td className="px-4 py-3 text-sm">{row.optimizername}</td>
                    <td className="px-4 py-3 text-sm">{row.bypasstype}</td>
                    <td className="px-4 py-3 text-sm">{getStatus(row)}</td>
                    <td className="px-4 py-3 text-sm">
                      {row.byPassAcknowledged}/{row.optimizerCount}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      {convertUnixToFormattedDate(row.starttime)}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      {row.endtime
                        ? convertUnixToFormattedDate(row.endtime)
                        : "--"}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      <div className="flex items-center justify-center space-x-2">
                        {/* Show the Delete Schedule button based on conditions */}
                        {row.bypasstype === "schedule" &&
                        startTimeMillis > currentTime &&
                        row.devicestatus === null ? (
                          <button
                            className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
                            onClick={() => openDeleteModal(row)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="red"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </button>
                        ) : null}

                        {/* Toggle Button */}
                        <div className="toggle_btn">
                        <input
                          type="checkbox"
                          id={`toggle-btn-${startIndex + index}`}
                          checked={row.status === "active" && row.devicestatus === "active"}
                          onChange={() => handleToggleChange(startIndex + index, row)}
                          className={`toggle_input ${
                            row.status !== "active" || row.devicestatus !== "active" ? "cursor-not-allowed" : ""
                          }`}
                          disabled={row.status !== "active" || row.devicestatus !== "active"}
                        />
                          <label htmlFor={`toggle-btn-${startIndex + index}`} className="toggle_label">
                            <span className="toggle_span"></span>
                          </label>
                        </div>
                      </div>

                      {/* Open Delete Modal */}
                      {isDeleteModelOpen && (
                        <ByPassDeleteModal
                          closeModal={() => setIsDeleteModelOpen(false)}
                          Data={selectedDeleteItem} 
                          onDeleteSuccess={handleDeleteSuccess} 
                        />
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>

        <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
          <span className="flex items-center col-span-3">
            {`Showing ${startIndex + 1}-${endIndex} of ${bypassList.length}`}
          </span>
          <span className="col-span-2"></span>
          {/* Pagination  */}

          <span className="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
            <nav aria-label="Table navigation">
              <ul className="inline-flex items-center">
                <li>
                  <button
                    className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                    aria-label="Previous"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 fill-current"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </li>
                {renderPaginationButtons()}
                <li>
                  <button
                    className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                    aria-label="Next"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <svg
                      className="w-4 h-4 fill-current"
                      aria-hidden="true"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ByPassList;