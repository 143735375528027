//import { configureStore, createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ACONOFF
} from "../../api/api";

export const fetchOnOff = createAsyncThunk(
  "OptimizerOnOff",
  async ({ data, header }, { getState }) => {
  
    const response = await ACONOFF(data, header);
    return response.data;
  }
);

const ACOnOffSlice = createSlice({
  name: 'OptimizerOnOff',
  initialState: {
    aconoff: [],
    aggData:[],
    isloading: false,
    error: null,
  },
  reducers: {    
    clearGatewaysResponse: (state) => {
      state.aconoff = [];
      state.aggData = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnOff.pending, (state) => {
        state.isloading = true;
        state.error = null;
      })
      .addCase(fetchOnOff.fulfilled, (state, action) => {
        state.isloading = false;
        state.aconoff = action.payload.data;
        state.aggData = action.payload.aggData;
      })
      .addCase(fetchOnOff.rejected, (state, action) => {
        state.isloading = false;
        state.error = action.error.message;
      });
  },
});

export const {clearGatewaysResponse} = ACOnOffSlice.actions;

export default ACOnOffSlice.reducer;