import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BYPASS,
  SCHEDULE_BYPASS,
  CANCEL_SCHEDULE_BYPASS,
  INSTANT_BYPASS_ONOFF,
  BYPASS_LIST,
  BYPASS_SCHEDULE,
  DELETE_BYPASS,
  TURN_ONOFF_BYPASS,
} from "../../api/api";

export const ByPassListing = createAsyncThunk(
  "ByPassListing",
  async ({ EnterpriseId, data, header }, { rejectWithValue }) => {
    try {
      const response = await BYPASS_LIST(EnterpriseId, data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const ByPassSchedule = createAsyncThunk(
  "ByPassSchedule",
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await BYPASS_SCHEDULE(data, header);
      console.log("API Response:", response);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeleteBypass = createAsyncThunk(
  "DeleteBypass",
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await DELETE_BYPASS(data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const TurnOnOffBypass = createAsyncThunk(
  "TurnOnOffBypass",
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await TURN_ONOFF_BYPASS(data, header);
      console.log(response.data);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const ScheduleBypass = createAsyncThunk(
  "ScheduleBypass",
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await SCHEDULE_BYPASS(data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const InstantBypassOnOff = createAsyncThunk(
  "InstantBypassOnOff",
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await INSTANT_BYPASS_ONOFF(data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const CancelScheduleByPass = createAsyncThunk(
  "CancelScheduleByPass",
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await CANCEL_SCHEDULE_BYPASS(data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const Bypass = createAsyncThunk(
  "Bypass",

  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await BYPASS(data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  bypassList: [],

  status: "",
  loading: false,

  bypass_response: "",
  bypass_error: null,

  bypass_list_response: "",
  bypass_list_error: null,

  bypass_schedule_response: "",
  bypass_schedule_error: null,

  bypass_delete_response: "",
  bypass_delete_error: null,

  bypass_turnonoff_response: "",
  bypass_turnonoff_error: null,
};

export const BypassSlice = createSlice({
  name: "byPassSlice",
  initialState,
  reducers: {
    // Clear all errors and responses
    clearError: (state) => {
      state.bypass_error = null;
    },
    clearResponse: (state) => {
      state.bypass_response = "";
    },

    clear_bypasslist_response: (state) => {
      state.bypass_list_response = "";
    },
    clear_bypassschedule_response: (state) => {
      state.bypass_schedule_response = "";
    },
    clear_bypassdelete_response: (state) => {
      state.bypass_delete_response = "";
    },
    clear_bypassonoff_response: (state) => {
      state.bypass_turnonoff_response = "";
    },

    clear_bypasslist_error: (state) => {
      state.bypass_list_error = null;
    },
    clear_bypassschedule_error: (state) => {
      state.bypass_schedule_error = null;
    },
    clear_bypassdelete_error: (state) => {
      state.bypass_delete_error = null;
    },
    clear_bypassonoff_error: (state) => {
      state.bypass_turnonoff_error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(Bypass.pending, (state) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(Bypass.fulfilled, (state, { payload }) => {
        state.status = "Success";
        state.loading = false;
        state.bypass_response = payload;
        state.bypass_error = null;
      })
      .addCase(Bypass.rejected, (state, { payload }) => {
        state.status = "Failed";
        state.loading = false;
        state.bypass_error = payload;
      })

      // ByPass Listing
      .addCase(ByPassListing.pending, (state) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(ByPassListing.fulfilled, (state, { payload }) => {
        state.bypass_list_response = payload;
        state.status = "Success";
        state.loading = false;
        state.bypass_list_error = null;
      })
      .addCase(ByPassListing.rejected, (state, { payload }) => {
        state.status = "Failed";
        state.loading = false;
        state.bypass_list_error = payload;
      })

      //ByPass Schedule
      .addCase(ByPassSchedule.pending, (state) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(ByPassSchedule.fulfilled, (state, { payload }) => {
        state.bypass_schedule_response = payload;
        state.status = "Success";
        state.loading = false;
        state.bypass_schedule_error = null;
      })
      .addCase(ByPassSchedule.rejected, (state, { payload }) => {
        state.status = "Failed";
        state.loading = false;
        state.bypass_schedule_error = payload;
      })

      //ByPass Delete
      .addCase(DeleteBypass.pending, (state) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(DeleteBypass.fulfilled, (state, { payload }) => {
        state.bypass_delete_response = payload;
        state.status = "Success";
        state.loading = false;
        state.bypass_delete_error = null;
      })
      .addCase(DeleteBypass.rejected, (state, { payload }) => {
        state.status = "Failed";
        state.loading = false;
        state.bypass_delete_error = payload;
      })

      //ByPass Turn On Off
      .addCase(TurnOnOffBypass.pending, (state) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(TurnOnOffBypass.fulfilled, (state, { payload }) => {
        state.bypass_turnonoff_response = payload;
        state.status = "Success";
        state.loading = false;
        state.bypass_turnonoff_error = null;
      })
      .addCase(TurnOnOffBypass.rejected, (state, { payload }) => {
        state.status = "Failed";
        state.loading = false;
        state.bypass_turnonoff_error = payload;
      });
  },
});

export const { clearError, 
            clearResponse, 
            clear_bypasslist_response,
            clear_bypassschedule_response,
            clear_bypassdelete_response,
            clear_bypassonoff_response,
            clear_bypasslist_error,
            clear_bypassschedule_error,
            clear_bypassdelete_error,
            clear_bypassonoff_error, } = BypassSlice.actions;
export default BypassSlice.reducer;
